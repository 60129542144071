import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import BreguetCategoryBtns from '../../components/preowned/BreguetCategoryBtns'

// markup
const BreguetMinuteRepeater = () => {
  const data = useStaticQuery(
    graphql`
      query BreguetMinuteRepeater {
        products: allStrapiProduct(
          filter: { brand: { eq: "Breguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Breguet Minute Repeater Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/breguet/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Breguet Minute Repeater watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/breguet/">
              <StaticImage
                src="../../images/preowned/banner4.jpg"
                alt="Pre-Owned Certified Used Breguet Watches Header"
                aria-label="Used Breguet Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          ...ProductCard
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Breguet Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE BREGUET WATCHES AT GRAY AND
              SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED BREGUET WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              <br />
              Get the Pre-Owned Breguet Minute Repeater of your dreams at #1 Watch buyer and seller,
              Gray and Sons in Miami Beach, Florida. Breguet is one of Switzerland’s finest watch
              brands, charged with carrying on the legacy of the legendary watchmaker, Abraham-Louis
              Breguet. A great innovator, the late Abraham-Louis Breguet has a bevy of timekeeping
              inventions (such as the tourbillon) and watch design creations (such as Breguet hands)
              to his name. Fast-forward over two centuries later, and his namesake brand, Breguet,
              continues the tradition of classical timepiece designs, fine hand-finished
              craftsmanship, and exquisite mechanical watch movements and complications.
              <br />
              <br />
              A spectacular example of Breguet’s mastery of high horology is the Classique Grande
              Complication ref. 5447PT, which marries a perpetual calendar and minute repeater in a
              handsomely finished platinum case. Keep reading to learn more about this edition of
              our Watch of the Month series, the platinum Breguet Classique Grande Complication
              5447.
              <br />
              <br />
              <Link to="/w525491-breguet-minute-repeater-40mm-5447pt1e/">
                <b>Breguet 5447PT Minute Repeater Perpetual Complication</b>
              </Link>
              <br />
              In 2005, Breguet unveiled the Classique Grande Complication reference 5447PT/1E/9V6
              Perpetual Calendar Minute Repeater at the Baselworld watch fair. As denoted by the
              “PT” letters in its reference number, this particular edition is crafted in platinum
              (Breguet does offer other variants in rose gold and white gold). This platinum Breguet
              watch is paired with a black alligator leather strap, complete with a platinum
              deployant clasp.
              <br />
              <br />
              The platinum case measures 40mm, which is a remarkably restrained size given all the
              complications of the watch. In true Breguet fashion, the case is decorated with fine
              fluting on the sides while the slim bezel makes way for more real estate on the dial.
              Similar to many Breguet fine timepieces, the silvered gold dial of this Classique
              Grande Complication 5447 is intricately decorated with several guilloche patterns,
              hand-engraved on a rose engine. There are so many details to take in that it’s
              completely natural to see something new each time you glance at the dial.
              <br />
              <br />
              One example of these advances is their 1790 pare-chute system which allowed timepieces
              to absorb shocks without compromising the accuracy and integrity of the mechanism’s
              balance wheels. Breguet became one of the first to create a shock-proof timepiece.
              Later in 1795, they invented the Tourbillon escapement which later became patented by
              Breguet in 1801. The tourbillon escapement introduced a solution to a movement’s worst
              enemy, gravity. Breguet located all watch parts most sensitive to gravity inside a
              carriage which is constantly rotating, regulating any horological discrepancies.
              <br />
              <br />
              Breguet watches come in precious metals such as 14k and 18k yellow and rose and white
              gold as well as stainless steel. His and Hers models are made in all sizes for most
              collections. Breguet watches come in all shapes and sizes from 25mm to 41mm. Breguet
              is one of the few luxury watch brands whose vintage watches are as highly desired as
              their modern watches.
              <br />
              <br />
              Popular Pre-Owned Breguet Watch References are: Reference 1801BB/12/2W6, Reference
              3055BA, Reference 3130bb/11/986, Reference 3137bb/11/986, Reference 3300BA/12/286,
              Reference 3355OR, Reference 3380ba, Reference 3460ba/12/a90, Reference 3477pt/1E/986,
              Reference 3637BA/12/986, Reference 3657PT129V6, Reference 3680br/11/986, Reference
              3757PT/ 1e/9v6, Reference 3800plat.
              <br />
              <br />
              Clients looking to get a better deal on pre-owned Breguet watches should visit Gray
              and Sons Jewelers. At G&amp;S, we carry all kinds of used Breguet watches including
              discontinued Breguet and collectible Breguet watches. Vintage Breguet and Modern
              Breguet watches are sold and bought weekly by Gray and Sons from clients all around
              the nation.
              <br />
              <br />
              All around the world, Breguet watches are recognized and respected as fine swill
              luxury timepieces. Clients who want to add a Breguet watch to their collection should
              only trust that Gray and Sons Jewelers is the best new and used Breguet watch
              specialist. Here at Gray and Sons Jewelers, we have six Swiss-trained watchmakers with
              over 150 years of combined experience who fully service every single Breguet watch in
              our inventory.
              <br />
              <br />
              We provide repair services to the public as well as provide a 1-year warranty for all
              Breguet watches sold at Gray and Sons. In-house we offer the following services:
              cleaning, polishing, overhaul, and lubrication of the movement, battery changes,
              waterproofing, and much more! Come visit our store to repair my Breguet watch.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/breguet/">
                <button>SHOP BREGUET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK BREGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <BreguetCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default BreguetMinuteRepeater
